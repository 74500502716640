import * as React from "react"

import "./home.css"
import Logo from "@assets/CNlogo.png";
import ImageStack from "@components/ImageStack/ImageStack";

const ALL_IMAGES_QUERY = `query AllImages {
    allUploads(filter: {tags: {notIn: ["exhibition", "systemimage"]}}, first: "100") {
      url
    }
  }`;
  

const Home = () => {
    return (
        <div className="homeWrapper">
            <img alt="CrashToon Logotype" className="logo" src={Logo} />
            <div className="stackWrapper">
                <ImageStack query={ALL_IMAGES_QUERY}/>
            </div>
        </div>
    )
}

export default Home