import * as React from "react";

import "./artistimage.css";

import useVisibility from "@utils/useVisibility";

type ArtistImageProps = {
  image: string;
};

const ArtistImage: React.FC<ArtistImageProps> = ({ image }) => {
  const [isVisible, thisCompRef] = useVisibility<HTMLDivElement>();
  const [showImage, setShowImage] = React.useState(false);
  const [imgStyle, setImgStyle] = React.useState<React.CSSProperties>();

  React.useEffect(() => {
    if (isVisible && !showImage) {
      setShowImage(true);

      setTimeout(() => {
        const endPosX = Math.floor(Math.random() * 20);
        // const endPosY = Math.floor(Math.random() * 50);
        const endRot = Math.floor(Math.random() * 20);
        setImgStyle((prev) => {
          return {
            ...prev,
            marginLeft: `${-10 + endPosX}%`,
            transform: `rotate(${-10 + endRot}deg)`,
          };
        });
      }, 250);
    }
  }, [isVisible, showImage]);

  return (
    <div className="artist-image" ref={thisCompRef} style={imgStyle}>
      {showImage && <img alt="artist creation" src={image} />}
    </div>
  );
};

export default ArtistImage;
