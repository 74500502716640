export function shuffle(array: unknown[], debug?: boolean) {
     if(debug) {
        console.log("shuffle in:", array)
    }
    let m = array.length, t, i;
  
    // While there remain elements to shuffle…
    while (m) {
  
      // Pick a remaining element…
      i = Math.floor(Math.random() * m--);
  
      // And swap it with the current element.
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }

    if(debug) {
        console.log("shuffle out:", array)
    }
    return array;
}
