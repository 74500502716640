import * as React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "graphql-hooks";

// import artists, { ArtistProps } from "../../artists";

import { useWindowSize } from "@utils/useWindowSize";

import "./artists.css";

type Artist = {
  firstname: string;
  gallerypic: {
    url: string;
  };
  id: string;
  lastname: string;
};

type AllArtistsQuery = {
  allArtists: Artist[];
};

const ARTISTS_QUERY = `query ARTISTS_OVERVIEW {
  allArtists(orderBy: [lastname_ASC]) {
    id
    lastname
    firstname
    gallerypic {
      url
    }
  }
}
`;

const ArtistsPage: React.FC = () => {
  const [focus, setFocus] = React.useState<Artist>();
  const [style, setStyle] = React.useState<React.CSSProperties>();
  const { height, width } = useWindowSize();

  const { data, loading } = useQuery<AllArtistsQuery>(ARTISTS_QUERY);

  let links: React.ReactNode[] = [];
  let bgImages: React.ReactNode[] = [];

  React.useEffect(() => {
    if (data) {
      if (height > width) {
        setStyle({
          fontSize: `${70 / data.allArtists.length}vw`,
        });
      } else {
        setStyle({
          fontSize: `${60 / data.allArtists.length}vh`,
        });
      }
    }
  }, [data, height, width]);

  const rot = React.useCallback(() => {
    return Math.floor(Math.random() * 20);
  }, []);

  if (loading || !data) return null;

  const { allArtists } = data;

  allArtists.forEach((artist: Artist, index: number) => {
    const artistFullName = `${artist.firstname} ${artist.lastname}`;
    links.push(
      <Link
        className="artist-link"
        key={`artist-link-${index}`}
        onMouseEnter={() => setFocus(artist)}
        onMouseLeave={() => setFocus(undefined)}
        to={`/artist/${encodeURI(artistFullName)}`}
      >
        {artistFullName}
      </Link>
    );
    if (artist.gallerypic) {
      bgImages.push(
        <img
          className={`artist-background${
            artist === focus ? ` artist-background-focus` : ``
          }`}
          alt={artistFullName}
          key={`artist-image-${index}`}
          src={artist.gallerypic.url}
          style={{
            transform: `translate3d(-50%, -50%, 0) rotate(${-10 + rot()}deg)`,
          }}
        />
      );
    } else {
      bgImages.push(null);
    }
  });

  return (
    <div className="artists-wrapper" style={style}>
      <div className="artists-list">{links}</div>
      {bgImages}
    </div>
  );
};

export default ArtistsPage;
