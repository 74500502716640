import React, { useEffect, useState } from "react";
import "./item.css";

export default function SliderItem({
  src,
  stepTime,
}: {
  src: string;
  stepTime: number;
}) {
  let baseItemStyle = {
    left: "-100%",
  } as React.CSSProperties;

  let baseImgStyle = {
    marginLeft: "0vw",
    marginTop: "0vh",
    transitionProperty: "box-shadow, transform, margin",
    transitionDuration: `2.5s`,
    transitionTimingFunction: "ease-out",
  } as React.CSSProperties;

  const [itemStyle, setItemStyle] =
    useState<React.CSSProperties>(baseItemStyle);
  const [imgStyle, setImgStyle] =
    React.useState<React.CSSProperties>(baseImgStyle);

  useEffect(() => {
    if (stepTime) {
      setTimeout(() => {
        const endPosX = Math.floor(Math.random() * 80);
        const endPosY = Math.floor(Math.random() * 80);
        const endRot = -20 + Math.floor(Math.random() * 40);

        setItemStyle((prev) => {
          return {
            ...prev,
            left: "50%",
          };
        });

        setImgStyle((prev) => {
          return {
            ...prev,
            marginLeft: `${-40 + endPosX}vw`,
            marginTop: `${-40 + endPosY}vh`,
            transform: `rotate(${endRot}deg)`,
          };
        });
      }, stepTime);
    }
  }, [stepTime]);

  return (
    <div style={itemStyle} className="imageslider-item">
      <img
        className="imageslider-item-img"
        style={imgStyle}
        src={src}
        alt="gallery-item"
      />
    </div>
  );
}
