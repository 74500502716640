import * as React from "react";
import { useQuery } from "graphql-hooks";
import "./ar.css";

type ARQuery = {
  arpage: {
    artbuttonimage: {
      url: string;
    };
    backgroundimage: {
      url: string;
    };
    pagecontent: string;
    nftbuttonimage: {
      url: string;
    };
  };
};

const AR_QUERY = `query AR_QUERY {
  arpage {
    pagecontent
    nftbuttonimage {
      url
    }
    artbuttonimage {
      url
    }
    backgroundimage {
      url
    }
  }
}
`;

const AR: React.FC = () => {
  const { data, loading } = useQuery<ARQuery>(AR_QUERY);

  if (!data || loading) return null;

  return (
    <div className="ar page-width">
      <div className="button-box">
        <a className="link link-art" href="https://webar.crashtoonnetwork.com">
          <img alt="art" src={data.arpage.artbuttonimage.url} />
        </a>
        <a className="link link-nft" href="https://nft.crashtoonnetwork.com">
          <img alt="nft" src={data.arpage.nftbuttonimage.url} />
        </a>
        <img alt="ar" className="bg" src={data.arpage.backgroundimage.url} />
      </div>
      <div
        className="page-content"
        dangerouslySetInnerHTML={{ __html: data.arpage.pagecontent }}
      />
    </div>
  );
};

export default AR;

// function WebAR() {
//   window.location.replace('https://se559319790701.8thwall.app/main-endless/');
//   return null;
// }

// export default WebAR;
