import * as React from "react";
import "./imagestack.css";
import { useQuery } from "graphql-hooks";

import { shuffle } from "./utils";

import { useWindowSize } from "@utils/useWindowSize";

import Item, { ItemProps } from "./components/Item";

const numPoints = 12;

interface ItemStackProps {
  itemLife?: number;
  itemSpeed?: number;
  stepTime?: number;
  query: string;
}

type UploadProps = {
  url: string;
};

type AllImagesQueryProps = {
  allUploads: UploadProps[];
};

const ImageStack: React.FC<ItemStackProps> = ({
  stepTime = 500,
  itemLife = 2000,
  itemSpeed = 1000,
  query,
}) => {
  const intervalRef = React.useRef<NodeJS.Timer>();
  const { height, width } = useWindowSize();

  const [pointArray] = React.useState(
    shuffle(Array.from(Array(numPoints).keys()))
  );
  const [itemArray, setItemArray] = React.useState<ItemProps[]>([]);
  const [sourceArray, setSourceArray] = React.useState<string[]>([]);

  const { data, loading } = useQuery<AllImagesQueryProps>(query);

  React.useEffect(() => {
    if (data) {
      const getUrlArray = data.allUploads.map((item) => {
        return item.url;
      });
      setSourceArray(shuffle(getUrlArray) as string[]);
    }
  }, [data]);

  const step = React.useCallback(
    (counter: number) => {
      const pointCounter = counter % pointArray.length;
      const sourceCounter = counter % sourceArray.length | 0;

      const whereToPutIt = pointArray[pointCounter] as number;
      const whatToPut = {
        image: sourceArray[sourceCounter],
        live: itemLife,
        order: counter,
        point: whereToPutIt,
        speed: itemSpeed,
      };

      setItemArray((prev) => [...prev, whatToPut]);
    },
    [pointArray, sourceArray, itemLife, itemSpeed]
  );

  React.useEffect(() => {
    let counter = 0;
    const id = setInterval(() => {
      counter++;
      if (counter > 20) clearInterval(intervalRef.current);
      step(counter);
    }, stepTime);

    intervalRef.current = id;

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [step, stepTime]);

  if (!sourceArray || loading) return null;

  const angle = 360 / pointArray.length;

  return (
    <div className="imageStack">
      {pointArray.map((_point, i) => {
        const pointStyle = {
          transform: `rotate(${angle * i}deg)`,
        };
        const pathStyle = {
          width: width >= height ? width : height,
        };
        return (
          <div className="stackPoint" key={i} style={pointStyle}>
            <div className="stackPath" style={pathStyle}>
              {itemArray.map((item, j) => {
                if (item.point === i) {
                  return (
                    <Item
                      {...item}
                      angle={angle}
                      key={j}
                      // removeMe={test}
                    />
                  );
                }
                return null;
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ImageStack;
