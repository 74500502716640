import * as React from "react";
import "./team.css";
import { useQuery } from "graphql-hooks";

import { useWindowSize } from "@utils/useWindowSize";

type TeamMemberProps = {
  firstname: string;
  details: string;
  lastname: string;
  profilepic: {
    url: string;
  };
};

type TeamMembersQueryProps = {
  allTeamMembers: TeamMemberProps[];
};

const TEAM_MEMBERS_QUERY = `query GetTeamMembers {
  allTeamMembers(orderBy: lastname_ASC) {
    firstname
    details
    lastname
    profilepic {
      url
    }
  }
}
`;

type TeamPageQueryProps = {
  teampage: {
    pagecontent: string;
  };
};

const TEAM_PAGE_QUERY = `query MISSON_PAGE_QUERY {
  teampage {
    pagecontent
  }
}
`;

const Team: React.FC = () => {
  const [focus, setFocus] = React.useState<TeamMemberProps>();
  const [style, setStyle] = React.useState<React.CSSProperties>();
  const { data, loading } = useQuery<TeamMembersQueryProps>(TEAM_MEMBERS_QUERY);
  const { data: team, loading: teamloading } =
    useQuery<TeamPageQueryProps>(TEAM_PAGE_QUERY);

  const { height, width } = useWindowSize();

  let members: React.ReactNode[] = [];
  let details: React.ReactNode[] = [];

  React.useEffect(() => {
    if (data) {
      const { allTeamMembers } = data;
      if (height > width) {
        setStyle({
          fontSize: `${35 / allTeamMembers.length}vw`,
        });
      } else {
        setStyle({
          fontSize: `${30 / allTeamMembers.length}vh`,
        });
      }
    }
  }, [data, height, width]);

  const rot = React.useCallback(() => {
    return Math.floor(Math.random() * 20);
  }, []);

  if (!data || loading || !team || teamloading) return null;

  const { allTeamMembers } = data;

  allTeamMembers.forEach((member, index) => {
    members.push(
      <div
        className="team-member"
        key={`link-${index}`}
        onMouseEnter={() => setFocus(member)}
        onMouseLeave={() => setFocus(undefined)}
        style={style}
      >
        {`${member.firstname} ${member.lastname}`}
      </div>
    );
    details.push(
      <div
        key={`member-details-${index}`}
        className={`member-details${
          member === focus ? ` member-details-focus` : ``
        }`}
        style={{
          transform: `rotate(${-10 + rot()}deg)`,
        }}
      >
        <img
          alt={`${member.firstname} ${member.lastname}`}
          src={member.profilepic.url}
        />
        <div>{member.details}</div>
      </div>
    );
  });

  return (
    <div className="team-wrapper page-width">
      <div className="team-list">
        <div>{members}</div>
        <div
          className="team-extras"
          dangerouslySetInnerHTML={{ __html: team.teampage.pagecontent }}
        />
      </div>
      <div className="member-wrapper">{details}</div>
    </div>
  );
};

export default Team;
