import * as React from "react";
import Logo from "@assets/CNlogo.gif";
import { useQuery } from "graphql-hooks";

import { Link } from "react-router-dom";

import "./header.css";

import MobileMenuBtn from "@assets/mobile-menu-button.png";

type PageQueryProps = {
  exhibitionpage: {
    enabled: boolean;
  };
  arpage: {
    enabled: boolean;
    redirecturl: string;
  };
};

const PAGE_QUERY = `query PageQuery {
  exhibitionpage {
    enabled
  }
  arpage {
    enabled
    redirecturl
  }
}
`;

const MainMenu: React.FC = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { data, loading } = useQuery<PageQueryProps>(PAGE_QUERY);

  const headerClass = mobileOpen ? "header-open" : "";

  if (!data) return null;

  const showAR = !loading && data.arpage.enabled;
  const showExhibition = !loading && data.exhibitionpage.enabled;

  return (
    <header className={headerClass}>
      <div className="page-width header-contents">
        <Link to="/">
          <img alt="CrashToon Logotype" className="logo" src={Logo} />
        </Link>
        <img
          alt="mobile menu button"
          className="mobile-menu"
          onClick={() => setMobileOpen(!mobileOpen)}
          src={MobileMenuBtn}
        />
        <nav className="main-menu">
          {showAR && (
            <Link to="/ar" onClick={() => setMobileOpen(false)}>
              A/R
            </Link>
          )}
          {showExhibition && (
            <Link to="/exhibition" onClick={() => setMobileOpen(false)}>
              Exhibition
            </Link>
          )}
          <Link to="/artists" onClick={() => setMobileOpen(false)}>
            Artists
          </Link>
          <Link to="/mission" onClick={() => setMobileOpen(false)}>
            Mission
          </Link>
          <Link to="/team" onClick={() => setMobileOpen(false)}>
            Team
          </Link>
          <a
            rel="noopener noreferrer"
            href="https://opensea.io/Parallelcollective"
          >
            NFT
          </a>
        </nav>
      </div>
    </header>
  );
};

export default MainMenu;
