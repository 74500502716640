import "./imagegallery.css";
import React from "react";

export default function ImageGallery({
  images,
}: {
  images: { url: string }[];
}) {
  //   const [currentSlide, setCurrentSlide] = React.useState(0);
  const carouselWrapperRef = React.useRef<HTMLDivElement>(null);
  //   const numberOfSlides = images.length;

  //   function getCurrentSlideIndex(): number {
  //     if (!carouselWrapperRef.current) return 0;
  //     const scrollPosition = carouselWrapperRef.current.scrollLeft;
  //     const scrollWidth = carouselWrapperRef.current.scrollWidth;
  //     const clientWidth = carouselWrapperRef.current.clientWidth;
  //     const scrollPercentage =
  //       (scrollPosition / (scrollWidth - clientWidth)) * 100;
  //     const slideIndex = Math.round(
  //       scrollPercentage / (100 / (numberOfSlides - 1))
  //     );
  //     return slideIndex;
  //   }

  //   function handleScroll() {
  //     setCurrentSlide(getCurrentSlideIndex());
  //   }

  return (
    <div className="image-gallery">
      <div className="gallery-spacer" />
      <div
        className="gallery-images"
        // onScroll={handleScroll}
        ref={carouselWrapperRef}
      >
        {images.map((image, index) => {
          return (
            <div className="gallery-image" key={`carousel-slide-item-${index}`}>
              <img
                loading="lazy"
                alt={`gallery-${index}`}
                src={image.url}
                key={index}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
