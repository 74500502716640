import { useEffect, useState } from 'react';

// import { breakpoints } from 'Src/Variables';

// export enum Device {
//   Desktop = 'DESKTOP',
//   Tablet = 'TABLET',
//   Mobile = 'MOBILE',
// }

export const useWindowSize = (): { height: number; width: number } => {
  const [size, setSize] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  useEffect(() => {
    const handleResize = () => {
      setSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return size;
};

export const useWindowHeight = (): number => {
  const { height } = useWindowSize();
  return height;
};

export const useWindowWidth = (): number => {
  const { width } = useWindowSize();
  return width;
};

// export const useIsMobile = (): boolean => {
//   const width = useWindowWidth();
//   return width < breakpoints.Tablet;
// };

// export const useIsTablet = (): boolean => {
//   const width = useWindowWidth();
//   return width >= breakpoints.Tablet && width < breakpoints.Desktop;
// };

// export const useIsDesktop = (): boolean => {
//   const width = useWindowWidth();
//   return width >= breakpoints.Desktop;
// };

// export const useIsDevice = (): Device => {
//   const width = useWindowWidth();
//   if (width >= breakpoints.Desktop) {
//     return Device.Desktop;
//   } else if (width >= breakpoints.Tablet) {
//     return Device.Tablet;
//   } else {
//     return Device.Mobile;
//   }
// };