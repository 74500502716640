import * as React from "react";
import "./mission.css";
import { useQuery } from "graphql-hooks";

type MissionPageQueryProps = {
  missionpage: {
    image: {
      url: string;
    };
    pagecontent: string;
  };
};

const MISSON_PAGE_QUERY = `query MISSON_PAGE_QUERY {
  missionpage {
    image {
      url
    }
    pagecontent
  }
}
`;

const Mission: React.FC = () => {
  const { data, loading } = useQuery<MissionPageQueryProps>(MISSON_PAGE_QUERY);

  if (!data || loading) return null;

  const { missionpage } = data;

  return (
    <div className="mission page-width">
      <div className="mission-left">
        <img
          alt="mission animation"
          className="mission-animation"
          src={missionpage.image.url}
        />
      </div>
      <div
        className="mission-right"
        dangerouslySetInnerHTML={{ __html: missionpage.pagecontent }}
      />
    </div>
  );
};

export default Mission;
