import * as React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "graphql-hooks";

import "./artist.css";

import ArtistImage from "./components/ArtistImage";

type Artist = {
  collection: {
    url: string;
  }[];
  details: string;
  firstname: string;
  profilepic: {
    url: string;
  };
  lastname: string;
  quote: string;
};

type ArtisQuery = {
  artist: Artist;
};

const ARTIST_QUERY = `query ARTIST_QUERY($firstname: String, $lastname: String) {
    artist(filter: {firstname: {eq: $firstname}, lastname: {eq: $lastname}}) {
      id
      collection {
        url
      }
      quote
      lastname
      firstname
      details
      profilepic {
        url
      }
    }
  }  
  `;

const ArtistPage = () => {
  const [loaded, setLoaded] = React.useState(false);
  const { artist: urlArtist } = useParams();

  const urlParamArtist = decodeURI(urlArtist || "");
  const artistNames = urlParamArtist.split(" ");

  const firstName = artistNames.shift();
  const lastName = artistNames.join(" ");

  const { data, loading } = useQuery<ArtisQuery>(ARTIST_QUERY, {
    variables: {
      firstname: firstName,
      lastname: lastName,
      limit: 1,
    },
  });

  React.useEffect(() => {
    if (data) {
      setLoaded(true);
    }
  }, [data]);

  const rot = React.useCallback(() => {
    return Math.floor(Math.random() * 20);
  }, []);

  if (!data || loading) return null;

  const { artist } = data;

  return (
    <div
      className="artist-wrapper"
      style={{
        // backgroundColor: artistObject.profileColor,
        opacity: loaded ? 1 : 0,
      }}
    >
      <div className="artist-top">
        <div className="artist-left">
          {artist.profilepic && (
            <img
              alt="artist profile pic"
              src={artist.profilepic.url}
              style={{ transform: `rotate(${-10 + rot()}deg)` }}
            />
          )}
        </div>
        <div className="artist-right">
          <h1 className="artist-name page-width">{`${artist.firstname} ${artist.lastname}`}</h1>
          <div className="artist-details">
            {artist.quote && <q>{artist.quote}</q>}
            {artist.details && (
              <div dangerouslySetInnerHTML={{ __html: artist.details }} />
            )}
          </div>
        </div>
      </div>
      <div className="artist-images">
        {artist.collection.map((image, index) => {
          return <ArtistImage image={image.url} key={index} />;
        })}
      </div>
    </div>
  );
};

export default ArtistPage;
