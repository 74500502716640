import * as React from "react";
import "./mousecursor.css";

import image from "@assets/handpointer.png";

import { useWindowWidth } from "@utils/useWindowSize";

const MouseCursor: React.FC = () => {
  const cursor = React.useRef<HTMLDivElement>(null);

  const width = useWindowWidth();

  const mouseMove = (e: MouseEvent) => {
    if (cursor.current) {
      cursor.current.style.display = "block";
      cursor.current.style.left = e.pageX + "px";
      cursor.current.style.top = e.pageY + "px";
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousemove", mouseMove);

    return () => {
      document.removeEventListener("mousemove", mouseMove);
    };
  });

  if (width < 800) {
    return null;
  }

  return (
    <div ref={cursor} className="mouse-cursor">
      <img alt="mouse cursor" src={image} />
    </div>
  );
};

export default MouseCursor;
