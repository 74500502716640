import * as React from "react";

import "./footer.css";

const Footer: React.FC = () => {
  return (
    <footer>
      <div className="page-width footer-contents">
        created by the parallel collective
      </div>
    </footer>
  );
};

export default Footer;
