import * as React from "react";
import { useQuery } from "graphql-hooks";
import "./exhibition.css";
import ImageSlider from "@components/ImageSlider";
import ImageGallery from "@components/ImageGallery/ImageGallery";
// import ImageStack from "@components/ImageStack/ImageStack";

type ExhibitionPageQueryProps = {
  exhibitionpage: {
    contenttext: string;
    contentimage: {
      url: string;
    };
    gallery: {
      url: string;
    }[];
    gallerystack: {
      id: number;
      url: string;
    }[];
    introtext: string;
    intropic: {
      url: string;
    };
    title?: string;
  };
};

const EXHIBITION_PAGE_QUERY = `query EXHIBITION_PAGE_QUERY {
    exhibitionpage {
      contentimage {
        url
      }
      contenttext
      gallery {
        url
      }
      gallerystack {
        id
        url
      }      
      intropic {
        url
      }
      introtext
      title
    }
  }
  `;

const ExhibitionPage: React.FC = () => {
  const { data, loading } = useQuery<ExhibitionPageQueryProps>(
    EXHIBITION_PAGE_QUERY
  );

  if (!data || loading) return null;

  const { exhibitionpage } = data;

  return (
    <div className="exhibition-wrapper">
      <div className="exhibition-top">
        {exhibitionpage.intropic && (
          <img
            className="exhibition-top-image"
            alt="exhibition-intro-pic"
            src={exhibitionpage.intropic.url}
          />
        )}
        {exhibitionpage.title && (
          <h1 className="exhibition-title">{exhibitionpage.title}</h1>
        )}
        <div
          className="exhibition-introtext"
          dangerouslySetInnerHTML={{ __html: exhibitionpage.introtext }}
        />
      </div>
      <div className="exhibition-imagestack">
        {/* använd gallery från huvudqueryn istället */}
        {/* bygg ny komponent */}
        {/* <ImageStack query={EXHIBITION_PAGE_QUERY} /> */}
        <ImageSlider images={exhibitionpage.gallerystack} />
      </div>
      <div className="exhibition-content">
        <div className="exhibition-content-left">
          {exhibitionpage.contentimage && (
            <img src={exhibitionpage.contentimage.url} alt="exhibition" />
          )}
        </div>
        <div
          className="exhibition-content-right"
          dangerouslySetInnerHTML={{ __html: exhibitionpage.contenttext }}
        />
      </div>
      <div className="exhibition-gallery">
        <ImageGallery images={exhibitionpage.gallery} />
        {/* {exhibitionpage.gallery.map((image, index) => {
          return (
            <img
              loading="lazy"
              alt={`gallery-${index}`}
              src={image.url}
              key={index}
            />
          );
        })} */}
      </div>
    </div>
  );
};

export default ExhibitionPage;
