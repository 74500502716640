import * as React from "react";
import { Outlet } from "react-router-dom";

import MainMenu from "../Header/Header";
import Footer from "../Footer/Footer";

import "./layout.css";

const Layout: React.FC = () => {
  return (
    <>
      <MainMenu />
      <main className="layout">
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default Layout;
