import React from "react";
import SliderItem from "./components/sliderItem";
import useVisibility from "@utils/useVisibility";

import "./imageslider.css";

type ImageSliderProps = {
  images: {
    id: number;
    url: string;
  }[];
};

export default function ImageSlider({ images }: ImageSliderProps) {
  const [isVisible, thisCompRef] = useVisibility<HTMLDivElement>();
  const [render, setRender] = React.useState(false);

  const stepTime = 1250;

  React.useEffect(() => {
    if (isVisible) {
      setRender(true);
    }
  }, [isVisible]);

  return (
    <div ref={thisCompRef} className="imageslider">
      {render &&
        images.map((image, index) => {
          return (
            <SliderItem
              key={image.id.toString()}
              src={image.url}
              stepTime={(index + 0.1) * stepTime}
            />
          );
        })}
    </div>
  );
}
