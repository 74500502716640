import * as React from "react";

import "./item.css";

export interface ItemProps {
  image: string;
  live: number;
  order: number;
  point: number;
  speed: number;
}

interface ItemComponent extends ItemProps {
  angle: number;
  removeMe?: (order: number) => void;
}

const Item: React.FC<ItemComponent> = ({
  angle,
  image,
  order,
  point,
  speed,
}) => {
  let baseItemStyle = {
    left: "100%",
    transitionProperty: "left",
    transitionDuration: `${speed / 1000}s`,
    transitionTimingFunction: "ease-out",
    zIndex: order.toString(),
  } as React.CSSProperties;

  let baseImgStyle = {
    boxShadow: "20px 20px 15px 0px rgba(0,0,0,0.75)",
    marginLeft: "0vw",
    marginTop: "0vh",
    transform: `translate3d(-50%, -50%, 0) rotate(-${angle * point}deg)`,
    transitionProperty: "box-shadow, transform, margin",
    transitionDuration: `${speed / 1000}s`,
    transitionTimingFunction: "ease-out",
  } as React.CSSProperties;

  const [itemStyle, setItemStyle] =
    React.useState<React.CSSProperties>(baseItemStyle);
  const [imgStyle, setImgStyle] =
    React.useState<React.CSSProperties>(baseImgStyle);

  React.useEffect(() => {
    const endPosX = Math.floor(Math.random() * 50);
    const endPosY = Math.floor(Math.random() * 50);
    const endRot = Math.floor(Math.random() * 40);

    setTimeout(() => {
      setItemStyle((prev) => {
        return {
          ...prev,
          left: "0%",
        };
      });
      setImgStyle((prev) => {
        return {
          ...prev,
          boxShadow: "1px 2px 5px 1px rgba(0,0,0,0.75)",
          marginLeft: `${-25 + endPosX}vw`,
          marginTop: `${-25 + endPosY}vh`,
          transform: `translate3d(-50%, -50%, 0) rotate(-${
            angle * point - 20 + endRot
          }deg)`,
        };
      });
    }, 250);
    // setTimeout(() => {
    //   console.log("remove me");
    //   removeMe(order);
    // }, speed + live);
  }, [angle, point]);

  return (
    <div className="imagestack-item" style={itemStyle}>
      <img alt="artist creation" src={image} style={imgStyle} />
    </div>
  );
};

export default Item;
