import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  useRouteError
} from "react-router-dom";

import './global.css';

import AR from "./routes/ar";
import Artist from './routes/artist';
import Artists from "./routes/artists";
import Exhibition from "./routes/exhibition";
import Home from "./routes/home";
import Layout from "./components/layout/Layout"
import Mission from './routes/mission';
import Team from "./routes/team";

import MouseCursor from '@components/MouseCursor';

import { GraphQLClient, ClientContext } from "graphql-hooks";

const client = new GraphQLClient({
  url: "https://graphql.datocms.com",
  headers: {
    "Authorization": "Bearer 34d1e7933c17ae05169f20ce123c0c"
  }
})

const router = createBrowserRouter(
  [
    {
      element: <Layout />,
      path: "/",
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/ar",
          element: <AR />
        },
        {
          path: "/artists",
          element: <Artists />,
        },
        {
          path: "/artist/:artist",
          element: <Artist />
        },
        {
          path: "/exhibition",
          element: <Exhibition />
        },
        {
          path: "/mission",
          element: <Mission />
        },
        {
          path: "/team",
          element: <Team />
        }        
      ],
      errorElement: <ErrorBoundary />
    }
  ],
  {
    // basename: "/development",
  }
);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ClientContext.Provider value={client}>
      <MouseCursor />
      <RouterProvider router={router} fallbackElement={<div>loader</div>} />
    </ClientContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


function ErrorBoundary() {
  console.log("error render");
  let error = useRouteError();
  console.log(error);

  return <div>This page does not exist!</div>
}
